import { useContext } from "react"
import { DescLoja as DescLojaDictionary, InfosLoja as InfosLojaDictionary, Telefone as TelefoneDictionary } from "../shared/utils/dictionary"
import { AuthContext } from "../shared/context/auth"
import { postWhats } from "../service/api"
import { isMobile } from "../shared/utils/javascript"


export const Sociais = () => {

    const { telefone, ip, infosLoja: loja } = useContext(AuthContext);

    const telefoneWhats = telefone.find((x) => x.whatsapp === "1")

    return (
        <div className="fixed right-0 bottom-32 gap-3 flex flex-col z-50 mobile:top-auto mobile:bottom-0 mobile:flex-row mobile:gap-0 mobile:w-full">
            <div className="h-16 w-16 bg-[#26D367] rounded-full flex justify-center items-center mobile:w-full mobile:rounded-none mobile:h-12">
                <i onClick={() => {
                        postWhats({
                            ip: ip,
                            dispositivo: isMobile() ? 'MOBILE' : 'DESKTOP',
                            lojaId: Number(process.env.REACT_APP_COD_LOJA),
                            codVeiculo: 0
                        })
                        window.open(`https://api.whatsapp.com/send?phone=+55${telefoneWhats?.[TelefoneDictionary.TELEFONE].replace(/[( )-]/g,"")}&text=Olá, tudo bem? Estou acessando o site e gostaria de mais informações.`, "_blank")
                    }} className="ri-whatsapp-line ri-2x cursor-pointer text-white"></i>
            </div>
            <div className="h-16 w-16 bg-[#3B5999] rounded-full flex justify-center items-center mobile:rounded-none mobile:w-full mobile:h-12">
                <i onClick={() => window.open(loja?.[InfosLojaDictionary.LOJA][DescLojaDictionary.TBL_INFOS_LOJA][0][DescLojaDictionary.FACEBOOK], "_blank")} className="ri-facebook-line ri-2x cursor-pointer text-white"></i>
            </div>
            <div className="h-16 w-16 rounded-full flex justify-center items-center mobile:rounded-none mobile:w-full mobile:h-12" style={{background: "linear-gradient(222deg, #F00 13.01%, #FF00B8 50.59%, #FFDE7A 87.4%)"}}>
                <i onClick={() => window.open(loja?.[InfosLojaDictionary.LOJA][DescLojaDictionary.TBL_INFOS_LOJA][0][DescLojaDictionary.INSTAGRAM], "_blank")} className="ri-instagram-line ri-2x cursor-pointer text-white"></i>
            </div>
        </div>
    )
}