import { useNavigate } from 'react-router-dom'
import Logo from '../assets/logo.webp'
import { isMobile } from '../shared/utils/javascript';
import { useContext } from 'react';
import { AuthContext } from '../shared/context/auth';
import { postWhats } from '../service/api';
import { Telefone as TelefoneDictionary } from '../shared/utils/dictionary';
import { Endereco } from '../shared/utils/constants';

export const Header = () => {

    const navigate = useNavigate();
    const { telefone, ip } = useContext(AuthContext)

    const verifyRoute = (path: string) => {
        if(path === window.location.pathname) return true
    }
    const telefoneWhats = telefone.find((x) => x.whatsapp === "1")

    return (
        <div className='mobile:flex mobile:bg-black mobile:justify-center mobile:items-center mobile:p-3 mobile:flex-row'>
            <div className="bg-black py-5 flex items-center flex-col text-accent border-b border-b-[#3b3b3b] mobile:border-none px-3 mobile:py-3 mobile:justify-between">
                <div className='flex justify-around w-full items-center mobile:justify-center py-3 laptop:flex-col max-w-[1500px]'>
                    <img className='cursor-pointer object-cover w-64' onClick={() => navigate('/')} src={Logo} alt="Logo" />
                    {!isMobile() && 
                    <div className='flex gap-20 items-center w-full justify-around desktop:px-10'>
                    <div className='flex justify-between gap-10 min-h-10 items-center w-full'>
                        <div className='text-white flex items-center gap-3 justify-between w-full'>
                            <h2 onClick={() => navigate('/')} className={`cursor-pointer text-xl ${verifyRoute('/') ? "font-semibold" : ""}`}>Home</h2>
                            <h2 onClick={() => navigate('/sobre-nos')} className={`cursor-pointer text-xl ${verifyRoute('/sobre-nos') ? "font-semibold" : ""}`}>Sobre nós</h2>
                            <h2 onClick={() => navigate('/estoque')} className={`cursor-pointer text-xl ${verifyRoute('/estoque') ? "font-semibold" : ""}`}>Estoque</h2>
                            <h2 onClick={() => navigate('/financiamento')} className={`cursor-pointer text-xl ${verifyRoute('/financiamento') ? "font-semibold" : ""}`}>Financiamento</h2>
                            <h2 onClick={() => navigate('/avaliacao')} className={`cursor-pointer text-xl ${verifyRoute('/avaliacao') ? "font-semibold" : ""}`}>Venda seu veículo</h2>
                            <h2 onClick={() => navigate('/contato')} className={`cursor-pointer text-xl ${verifyRoute('/contato') ? "font-semibold" : ""}`}>Contato</h2>
                            <div>
                                <i onClick={() => navigate('/estoque')} className='ri-search-line ri-xl cursor-pointer text-black bg-white rounded-full p-1' />
                            </div>
                        </div>
                    </div>
                    </div>
                    }
                </div>
            </div>
            <div className='mobile:hidden laptop:hidden flex flex-row justify-between bg-[#1a1a1a] px-36 py-3 desktop:border-b-black desktop:border-b-2'>
                    <h2 className='text-white flex flex-row gap-2 cursor-pointer items-center' onClick={() => {
                        postWhats({
                            ip: ip,
                            dispositivo: isMobile() ? 'MOBILE' : 'DESKTOP',
                            lojaId: Number(process.env.REACT_APP_COD_LOJA),
                            codVeiculo: 0
                        })
                        window.open(`https://api.whatsapp.com/send?phone=+55${telefoneWhats?.[TelefoneDictionary.TELEFONE].replace(/[( )-]/g,"")}&text=Olá, tudo bem? Estou acessando o site e gostaria de mais informações.`, "_blank")
                    }}><i className="ri-phone-fill ri-xl"></i> {`${telefoneWhats?.telefone}`}</h2>
                    <h2 className='text-white flex flex-row gap-2 items-center'><i className="ri-map-pin-2-fill ri-xl"></i> {Endereco.endereco}</h2>
            </div>
                {isMobile() ? (
                    <div className="dropdown dropdown-end">
                        <div tabIndex={0} role="button" className="btn text-white outline-none border-none"><i className="ri-menu-line ri-3x font-normal"></i></div>
                        <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-max">
                        <li><h2 onClick={() => navigate('/')} className={`cursor-pointer ${verifyRoute('/') ? "font-semibold" : ""}`}>Home</h2></li>
                        <li><h2 onClick={() => navigate('/sobre-nos')} className={`cursor-pointer ${verifyRoute('/sobre-nos') ? "font-semibold" : ""}`}>Sobre nós</h2></li>
                        <li><h2 onClick={() => navigate('/estoque')} className={`cursor-pointer ${verifyRoute('/estoque') ? "font-semibold" : ""}`}>Estoque</h2></li>
                        <li><h2 onClick={() => navigate('/financiamento')} className={`cursor-pointer ${verifyRoute('/financiamento') ? "font-semibold" : ""}`}>Financiamento</h2></li>
                        <li><h2 onClick={() => navigate('/avaliacao')} className={`cursor-pointer ${verifyRoute('/avaliacao') ? "font-semibold" : ""}`}>Venda seu veículo</h2></li>
                        <li><h2 onClick={() => navigate('/contato')} className={`cursor-pointer ${verifyRoute('/contato') ? "font-semibold" : ""}`}>Contato</h2></li>
                        </ul>
                    </div>
                ) : null}
        </div>
    )
}