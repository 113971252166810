import { useNavigate } from "react-router-dom"
import { Veiculo } from "../shared/types"
import { Telefone as TelefoneDictionary, Veiculo as VeiculoDictionary } from "../shared/utils/dictionary"
import { ModalProposta } from "./modalProposta"
import semImagem from '../assets/semImagem.webp'
import { useContext } from "react"
import { AuthContext } from "../shared/context/auth"
import { postWhats } from "../service/api"
import { isMobile } from "../shared/utils/javascript"

type CardVeiculoType = {
    veiculo: Veiculo
}

export const CardVeiculo = ({ veiculo }: CardVeiculoType) => {

    const { telefone, ip } = useContext(AuthContext);
    const navigate = useNavigate();

    const telefoneWhats = telefone.find((x) => x.whatsapp === "1")

    const img = `https://litoralcar.com.br/foto-resize-site/X/${veiculo?.[VeiculoDictionary.COD_VEICULO]}/${process.env.REACT_APP_COD_LOJA}/${veiculo?.[VeiculoDictionary.FOTOS][0]}`

    const cambio = veiculo[VeiculoDictionary.OPCIONAIS].find(x => x.codigo === 21)

    const handleNavigate = () => {
        const paramsObj = { 
            cod_veiculo: veiculo[VeiculoDictionary.COD_VEICULO],
            marca: veiculo[VeiculoDictionary.MARCA],
            modelo: veiculo[VeiculoDictionary.MODELO],
            versao: veiculo[VeiculoDictionary.VERSAO]
        };

        const paramsString = encodeURIComponent(JSON.stringify(paramsObj));
      
        const urlComObjeto = `/veiculo?params=${paramsString}`;
        navigate(urlComObjeto)
    }

    return (
        <div className="flex flex-col bg-black text-white rounded-xl mobile:min-w-[95vw] justify-between" style={{boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)"}}>
            <ModalProposta veiculo={veiculo} />
            {veiculo[VeiculoDictionary.FOTOS].length === 0 ? (
                <div className="relative pt-[75%] w-full">
                    <img src={semImagem} alt={veiculo[VeiculoDictionary.VEICULO]} className="cursor-pointer object-cover absolute rounded-t-xl w-full h-full top-0" onClick={handleNavigate} />
                </div>
            ) : (
                <div className="relative pt-[75%] w-full">
                    <img src={img} alt={veiculo[VeiculoDictionary.VEICULO]} className="cursor-pointer object-cover absolute rounded-t-xl w-full h-full top-0" onClick={handleNavigate} />
                </div>
            )}
            <div className="py-4 px-5 flex flex-col gap-5 w-full">
                <h2 className="text-xl laptop:text-lg">{veiculo[VeiculoDictionary.MARCA]} {veiculo[VeiculoDictionary.MODELO]} {veiculo[VeiculoDictionary.VERSAO]}</h2>
                <div className="flex justify-between">
                    <h1 className="font-extrabold text-2xl laptop:text-lg">
                        {veiculo[VeiculoDictionary.VALOR].toLocaleString('pt-BR', {
                            style: 'currency',
                            currency: 'BRL'
                        })}
                    </h1>
                    <div className="flex gap-2 items-center">
                        <button className="btn btn-primary text-black font-bold rounded-full min-h-0 h-7">VEJA MAIS</button>
                    </div>
                </div>
                <hr className="h-0.5 bg-accent w-full" />
                <div className="flex flex-row justify-between flex-wrap">
                    <div className="flex gap-2 items-center">
                        <i className="ri-calendar-2-line"></i>
                        <h2>{veiculo[VeiculoDictionary.ANO] ?? "-"}</h2>
                    </div>
                    <div className="flex gap-2 items-center">
                        <i className="ri-gas-station-fill"></i>
                        <h2>{veiculo?.[VeiculoDictionary.COMBUSTIVEL] ?? "-"}</h2>
                    </div>
                    <div className="flex gap-2 items-center">
                        <i className="ri-dashboard-3-line"></i>
                        <h2>{Number(veiculo[VeiculoDictionary.QUILOMETRAGEM]).toLocaleString()}Km</h2>
                    </div>
                </div>
                
            </div>
        </div>
    )
}