import { useContext, useEffect, useRef, useState } from "react";
import { NomeLoja, Veiculo as VeiculoType } from '../shared/types/index'
import { Veiculo as VeiculoDictionary, Telefone as TelefoneDictionary, NomeLoja as NomeLojaDictionary } from '../shared/utils/dictionary'
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";

import semImagem from '../assets/semImagem.webp'
import { CardVeiculo } from "../components/cardVeiculo";
import { isMobile, scrollToTop } from "../shared/utils/javascript";
import { getLoja, getVeiculo, postEmail, postWhats } from "../service/api";
import { AuthContext } from "../shared/context/auth";
import { useLocation } from "react-router-dom";


export const Veiculo = () => {

    const { telefone, ip, email, estoque } = useContext(AuthContext);

    const location = useLocation();
    const [open, setOpen] = useState(false);
    const [openEmailEnvio, setOpenEmailEnvio] = useState(false);
    const [veiculo, setVeiculo] = useState<VeiculoType>({
        ano: 0,
        categoria: "",
        combustivel: "",
        fotos: [],
        marca: "",
        finalPlaca: "",
        motor: "",
        obs_site: "",
        modelo: "",
        valor: 0,
        cor: "",
        cod_veiculo: 0,
        veiculo: "",
        versao: "",
        km: "",
        opcionais: [],
    })
    const [nomeLoja, setNomeLoja] = useState<NomeLoja>({
        nome_empresa: ''
    })

    const [formEnvio, setFormEnvio] = useState({
        nome: "",
        email: "",
        telefone: "",
        mensagem: `Olá, tenho interesse nesse: ${veiculo[VeiculoDictionary.MARCA]} ${veiculo[VeiculoDictionary.MODELO]} - ${veiculo[VeiculoDictionary.ANO]} - no valor de ${veiculo[VeiculoDictionary.VALOR].toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        })}`
    })
    const link = window.location.origin + window.location.pathname + window.location.search;
    const telefoneWhats = telefone.find((x) => x.whatsapp === "1")
    const [propaganda, setPropaganda] = useState<VeiculoType[]>([]);
    // Util do carrossel
    const [imgActive, setImgActive] = useState(0);
    const [imgOpen, setImgOpen] = useState<number>(0);
    const galleryRef = useRef<HTMLDivElement>(null);
    
    const handleOpen = (index: number) => {
        setImgOpen(index)
        setOpen(true)
    }  

    const handleImgActive = (direction: 'left' | 'right') => {
        setImgActive((prevImgActive) =>
          direction === 'left'
            ? prevImgActive === 0
              ? veiculo[VeiculoDictionary.FOTOS].length - 1
              : prevImgActive - 1
            : prevImgActive === veiculo[VeiculoDictionary.FOTOS].length - 1
            ? 0
            : prevImgActive + 1
        );
    };
    const handleGetVeiculo = async (cod: string) => {
        try {
            const result = await getVeiculo(cod)
            setVeiculo(result?.data.retorno ?? {
                ano: 0,
                categoria: "",
                combustivel: "",
                fotos: [],
                marca: "",
                finalPlaca: "",
                motor: "",
                obs_site: "",
                modelo: "",
                valor: 0,
                cor: "",
                cod_veiculo: 0,
                veiculo: "",
                versao: "",
                km: "",
                opcionais: [],
            }) 
        } catch {
            //
        }
    }
    const handleGetNomeLoja = async () => {
        try {
            const result = await getLoja()
            setNomeLoja(result?.data.retorno ?? {
                nome_empresa: ''
            }) 
        } catch {
            //
        }
    }
    
    useEffect(() => {
        handleGetNomeLoja();
    },[])

    useEffect(() => {
        setFormEnvio({
            nome: "",
            email: "",
            telefone: "",
            mensagem: `Olá, tenho interesse nesse: ${veiculo[VeiculoDictionary.MARCA]} ${veiculo[VeiculoDictionary.MODELO]} - ${veiculo[VeiculoDictionary.ANO]} - no valor de ${veiculo[VeiculoDictionary.VALOR].toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL'
            })}`
        })
    },[veiculo])
    
    const handleSubmit = () => {
        postEmail({
            to: email,
            replyTo: formEnvio.email,
            subject: "Interesse",
            html: {
                nome: formEnvio.nome,
                email: formEnvio.email,
                telefone: formEnvio.telefone,
                mensagem: formEnvio.mensagem,
            },
            lojaId: Number(process.env.REACT_APP_COD_LOJA),
        })
        setOpenEmailEnvio(true)
        setFormEnvio({
            nome: "",
            email: "",
            telefone: "",
            mensagem: `Olá, tenho interesse nesse: ${veiculo[VeiculoDictionary.MARCA]} ${veiculo[VeiculoDictionary.MODELO]} - ${veiculo[VeiculoDictionary.ANO]} - no valor de ${veiculo[VeiculoDictionary.VALOR].toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL'
            })}`
        })
    }
    useEffect(() => {
        let timeoutId: any;
    
        if (openEmailEnvio) {
          timeoutId = setTimeout(() => {
            setOpenEmailEnvio(false);
          }, 3000); // 3000 milissegundos = 3 segundos
        }
    
        return () => {
          clearTimeout(timeoutId);
        };
      }, [openEmailEnvio]);

    const handlePropaganda = () => {
        // Lógica da propaganda 
        const listaEmbaralhada = [...estoque].sort(() => Math.random() - 0.5);
        const itensAleatorios = listaEmbaralhada.slice(0, 3);
        setPropaganda(itensAleatorios);
    }

    useEffect(() => {
        handlePropaganda();
    },[estoque])


    useEffect(() => {
        const queryString = window.location.search;       
        const paramsString = new URLSearchParams(queryString).get('params');
    
        if (paramsString) {
            try {
                const paramsObj = JSON.parse(decodeURIComponent(paramsString));
                handleGetVeiculo(paramsObj[VeiculoDictionary.COD_VEICULO]);
            } catch (error) {
            //
            }
        }
        handlePropaganda()
        scrollToTop()
    }, [location.search]);
    
    useEffect(() => {
        // Obtém a largura de um item da galeria
        const itemWidth = galleryRef.current?.querySelector('.gallery-item')?.clientWidth || 0;
    
        // Calcula a posição de rolagem com base em imgActive
        const scrollPosition = itemWidth * imgActive;
    
        // Rola para a posição desejada
        galleryRef.current?.scrollTo({
          left: scrollPosition,
          behavior: 'smooth', // Adiciona uma animação suave (opcional)
        });
    }, [imgActive]);

    useEffect(() => {
        scrollToTop()
    },[window.location.pathname])

    const listaImagens = () => {
        const result = veiculo[VeiculoDictionary.FOTOS].map((x) => {
            return {src: `https://litoralcar.com.br/foto-resize-site/X/${veiculo[VeiculoDictionary.COD_VEICULO]}/${process.env.REACT_APP_COD_LOJA}/${x}`}
        })
        return result
    }

    return (
        <>
        <Lightbox
            open={open}
            plugins={[Zoom, Slideshow,Thumbnails]}
            index={imgOpen}
            close={() => setOpen(false)}
            slides={listaImagens()}
            styles={{ container: { backgroundColor: "rgba(0, 0, 0, .8)" } }}
        />

            <div className="px-36 py-16 flex bg-secondary flex-row mobile:px-3 mobile:py-8 mobile:flex-col">
                <div className="flex  flex-col gap-16">
                <div className="flex flex-row gap-10 justify-center mobile:flex-col">

                {/* Carrossel */}
                <div className="shadow p-10 mobile:p-3 mobile:py-5 bg-black">
                    <div>
                        {(veiculo?.[VeiculoDictionary.FOTOS].length === 0 && !isMobile()) && (
                            <div className="flex flex-row gap-3 select-none">
                                <div className={`max-w-[5vw] object-cover h-auto relative`}>
                                    <img className="h-full object-cover object-right" src={semImagem} alt="Imagem veículo" />
                                    <div onClick={() => handleImgActive("left")} className="bg-accent absolute top-[45%] right-[20%] cursor-pointer rounded-full h-12 w-12 flex items-center justify-center">
                                        <i className="ri-arrow-left-s-line ri-3x text-white"></i>
                                    </div>
                                </div>
                                <img src={semImagem} alt="Imagem veículo" className={`cursor-pointer object-cover max-w-[40vw]`} />
                                <div className={`max-w-[5vw] object-cover h-auto relative`}>
                                    <img className="h-full object-cover object-left" src={semImagem} alt="Imagem veículo" />
                                    <div onClick={() => handleImgActive("right")} className="bg-accent absolute top-[45%] left-[20%] cursor-pointer rounded-full h-12 w-12 flex items-center justify-center">
                                        <i className="ri-arrow-right-s-line ri-3x text-white"></i>
                                    </div>
                                </div>
                            </div>
                        )}
                        {(veiculo?.[VeiculoDictionary.FOTOS].length === 0 && isMobile()) && (
                            <div className="flex flex-row gap-3 select-none">
                                <img src={semImagem} alt="Imagem veículo" className={`cursor-pointer object-cover max-w-full`} />
                            </div>
                        )}
                        {(veiculo?.[VeiculoDictionary.FOTOS].length !== 0 && !isMobile()) && 
                            veiculo?.[VeiculoDictionary.FOTOS].map((x, i) => (
                                <div key={i} className="flex flex-row gap-3 select-none">
                                    <div className={`max-w-[5vw] object-cover h-auto relative ${imgActive === i ? "" : "hidden"}`}>
                                        <img className="h-full object-cover object-right" src={`https://litoralcar.com.br/foto-resize-site/X/${veiculo[VeiculoDictionary.COD_VEICULO]}/${process.env.REACT_APP_COD_LOJA}/${veiculo[VeiculoDictionary.FOTOS][imgActive === 0 ? veiculo[VeiculoDictionary.FOTOS].length - 1 : i - 1]}`} alt="Imagem veículo" />
                                        <div onClick={() => handleImgActive("left")} className="bg-accent absolute top-[45%] right-[20%] cursor-pointer rounded-full h-12 w-12 flex items-center justify-center">
                                            <i className="ri-arrow-left-s-line ri-3x text-white"></i>
                                        </div>
                                    </div>
                                    <img src={`https://litoralcar.com.br/foto-resize-site/X/${veiculo[VeiculoDictionary.COD_VEICULO]}/${process.env.REACT_APP_COD_LOJA}/${x}`} alt="Imagem veículo" className={`cursor-pointer object-cover max-w-[40vw] ${imgActive === i ? "" : "hidden"}`} onClick={() => handleOpen(i)} />
                                    <div className={`max-w-[5vw] object-cover h-auto relative ${imgActive === i ? "" : "hidden"}`}>
                                        <img className="h-full object-cover object-left" src={`https://litoralcar.com.br/foto-resize-site/X/${veiculo[VeiculoDictionary.COD_VEICULO]}/${process.env.REACT_APP_COD_LOJA}/${veiculo[VeiculoDictionary.FOTOS][imgActive === veiculo[VeiculoDictionary.FOTOS].length - 1 ? 0 : i + 1]}`} alt="Imagem veículo" />
                                        <div onClick={() => handleImgActive("right")} className="bg-accent absolute top-[45%] left-[20%] cursor-pointer rounded-full h-12 w-12 flex items-center justify-center">
                                            <i className="ri-arrow-right-s-line ri-3x text-white"></i>
                                        </div>
                                    </div>
                                </div>
                            )
                        )}
                        {(veiculo?.[VeiculoDictionary.FOTOS].length !== 0 && isMobile()) && 
                            veiculo?.[VeiculoDictionary.FOTOS].map((x, i) => (
                                <div key={i} className={`relative flex flex-row gap-3 select-none items-center cursor-pointer object-cover max-w-full ${imgActive === i ? "" : "hidden"}`}>
                                    <div onClick={() => handleImgActive("left")} className="bg-accent absolute left-2 cursor-pointer rounded-full h-12 w-12 flex items-center justify-center top-1/2 transform -translate-y-1/2">
                                        <i className="ri-arrow-left-s-line ri-3x text-white"></i>
                                    </div>
                                    <img src={`https://litoralcar.com.br/foto-resize-site/X/${veiculo[VeiculoDictionary.COD_VEICULO]}/${process.env.REACT_APP_COD_LOJA}/${x}`} alt="Imagem veículo" className={`cursor-pointer object-cover max-w-full ${imgActive === i ? "" : "hidden"}`} onClick={() => handleOpen(i)} />
                                    <div onClick={() => handleImgActive("right")} className="bg-accent absolute right-2 cursor-pointer rounded-full h-12 w-12 flex items-center justify-center top-1/2 transform -translate-y-1/2">
                                        <i className="ri-arrow-right-s-line ri-3x text-white"></i>
                                    </div>
                                </div>
                            )
                        )}
                        {isMobile() ? null : (
                            <div className="flex flex-row gap-5 mt-5 overflow-x-hidden max-w-[50vw]" ref={galleryRef}>
                            {veiculo[VeiculoDictionary.FOTOS].map((x, i) => (
                                <div
                                    key={i}
                                    className={`gallery-item flex-shrink-0 max-w-16 max-h-16 ${imgActive === i ? "brightness-100" : "brightness-50"}`}
                                    onClick={() => setImgActive(i)}
                                >
                                <img className="h-full object-cover object-left cursor-pointer" src={`https://litoralcar.com.br/foto-resize-site/X/${veiculo[VeiculoDictionary.COD_VEICULO]}/${process.env.REACT_APP_COD_LOJA}/${x}`} alt="Imagem veículo" />
                                </div>
                            ))}
                            </div>
                        )}
                    </div>

                    {/* Informações do veículo */}
                    <div className="mt-3 text-white flex gap-7 flex-col">
                        <div className="flex flex-col gap-4">
                            <h5>{veiculo[VeiculoDictionary.MARCA]} <span className="font-bold">{veiculo[VeiculoDictionary.MODELO]}</span></h5>
                            <h1>{veiculo[VeiculoDictionary.VEICULO]}</h1>
                        </div>
                        <div className="grid grid-cols-5 gap-y-8 mobile:grid-cols-2">
                            <div className="flex flex-col gap-1">
                                <h2>Ano</h2>
                                <h1>{veiculo[VeiculoDictionary.ANO] ? veiculo[VeiculoDictionary.ANO] : "-"}</h1>
                            </div>
                            <div className="flex flex-col gap-1">
                                <h2>Câmbio</h2>
                                <h1>{veiculo?.[VeiculoDictionary.OPCIONAIS].find(x => x.codigo === 21)?.descricao ? veiculo?.[VeiculoDictionary.OPCIONAIS].find(x => x.codigo === 21)?.descricao : "-"}</h1>
                            </div>
                            <div className="flex flex-col gap-1">
                                <h2>Cor</h2>
                                <h1>{veiculo[VeiculoDictionary.COR] ? veiculo[VeiculoDictionary.COR] : "-"}</h1>
                            </div>
                            <div className="flex flex-col gap-1">
                                <h2>Combustível</h2>
                                <h1>{veiculo[VeiculoDictionary.COMBUSTIVEL] ? veiculo[VeiculoDictionary.COMBUSTIVEL] : "-"}</h1>
                            </div>
                            <div className="flex flex-col gap-1">
                                <h2>Motor</h2>
                                <h1>{veiculo[VeiculoDictionary.MOTOR] ? veiculo[VeiculoDictionary.MOTOR] : "-"}</h1>
                            </div>
                            <div className="flex flex-col gap-1">
                                <h2>Quilometragem</h2>
                                <h1>{Number(veiculo[VeiculoDictionary.QUILOMETRAGEM]).toLocaleString()}</h1>
                            </div>
                            <div className="flex flex-col gap-1">
                                <h2>Final da placa</h2>
                                <h1>{veiculo[VeiculoDictionary.FINAL_PLACA] ? veiculo[VeiculoDictionary.FINAL_PLACA] : "-"}</h1>
                            </div>
                        </div>

                        <hr className="h-0.5 bg-accent w-full" />

                        <h2 className="font-semibold">Opcionais</h2>
                            <ul className="list-disc pl-5 grid grid-cols-2 mobile:grid-cols-2">
                                {veiculo[VeiculoDictionary.OPCIONAIS].map((x) => (
                                    <li key={x.codigo} className="w-3/4 mb-3">{x.descricao}</li>
                                ))}
                            </ul>

                        <hr className="h-0.5 bg-accent w-full" />

                        <h2 className="font-semibold">Observações</h2>
                        <h2 className="desktop:max-w-[50vw] text-justify">{veiculo[VeiculoDictionary.OBS_SITE]}</h2>

                        <div className="flex flex-col gap-4">
                            <h2 className="font-semibold">Compartilhar</h2>
                            <div className="flex flex-row gap-4">
                                <i onClick={() => window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(link)}&quote=Aqui na ${nomeLoja[NomeLojaDictionary.NOME_LOJA]} você encontra ${veiculo[VeiculoDictionary.MARCA]} ${veiculo[VeiculoDictionary.MODELO]} ${veiculo[VeiculoDictionary.MOTOR]} ${veiculo[VeiculoDictionary.ANO]} para negociação. Acesse e confira! ${link}`, "_blank")} className="ri-facebook-fill ri-xl text-[#0057FF] cursor-pointer"></i>
                                <i onClick={() => {
                                    postWhats({
                                        ip: ip,
                                        dispositivo: isMobile() ? 'MOBILE' : 'DESKTOP',
                                        lojaId: Number(process.env.REACT_APP_COD_LOJA),
                                        codVeiculo: 0
                                    })
                                    window.open(`https://api.whatsapp.com/send?text=Aqui na ${nomeLoja[NomeLojaDictionary.NOME_LOJA]} você encontra ${veiculo[VeiculoDictionary.MARCA]} ${veiculo[VeiculoDictionary.MODELO]} ${veiculo[VeiculoDictionary.MOTOR]} ${veiculo[VeiculoDictionary.ANO]} para negociação. Acesse e confira! ${encodeURIComponent(link)}`, "_blank")
                                }} className="ri-whatsapp-line ri-xl text-[#20B038] cursor-pointer"></i>
                            </div>
                        </div>

                    </div>
                </div>

                {/* Formulário de contato */}
                <div className="shadow p-10 flex flex-col gap-6 w-auto h-min sticky top-14 mobile:p-3 mobile:py-5 bg-black">

                    <h6 className="font-semibold text-white mobile:text-xl">{veiculo[VeiculoDictionary.VALOR].toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL'
                    })}</h6>

                    <hr className="h-0.5 bg-accent w-full" />

                    <h2 className="font-semibold text-white">Deixe sua mensagem caso tenha dúvidas</h2>

                    <div className="flex flex-col gap-5">
                        <input onChange={(event) => {
                            setFormEnvio(prevState => ({
                                ...prevState,
                                nome: event.target.value
                                }));
                        }} type="text" value={formEnvio.nome} placeholder="Nome" className="input input-bordered w-full" />
                        <input onChange={(event) => {
                            setFormEnvio(prevState => ({
                                ...prevState,
                                email: event.target.value
                                }));
                        }} type="text" value={formEnvio.email} placeholder="E-mail" className="input input-bordered w-full" />
                        <input onChange={(event) => {
                            setFormEnvio(prevState => ({
                                ...prevState,
                                telefone: event.target.value
                                }));
                        }} type="text" value={formEnvio.telefone} placeholder="Telefone" className="input input-bordered w-full" />
                        <textarea onChange={(event) => {
                            setFormEnvio(prevState => ({
                                ...prevState,
                                mensagem: event.target.value
                                }));
                        }} value={formEnvio.mensagem} placeholder="Mensagem" className="textarea textarea-bordered w-full min-h-32" />
                        <button className={`btn h-10 min-h-0 ${openEmailEnvio ? "btn-success font-normal text-white" : "btn-primary font-bold"}`} onClick={handleSubmit}>{openEmailEnvio ? "Mensagem enviada!" : "Enviar mensagem"}</button>
                    </div>

                    <div className="flex items-center gap-3">
                        <hr className="h-0.5 bg-accent w-full" />
                        <h2>ou</h2>
                        <hr className="h-0.5 bg-accent w-full" />
                    </div>

                    <button onClick={() => window.open(`https://api.whatsapp.com/send?phone=+55${telefoneWhats?.[TelefoneDictionary.TELEFONE].replace(/[( )-]/g,"")}&text=${`Olá, tenho interesse em um ${veiculo[VeiculoDictionary.MARCA]} ${veiculo[VeiculoDictionary.MODELO]} - ${veiculo[VeiculoDictionary.ANO]} - no valor de ${veiculo[VeiculoDictionary.VALOR].toLocaleString('pt-BR', {
                        style: 'currency',
                        currency: 'BRL'
                    })}`} que encontrei no seu site.`, "_blank")} className="btn btn-success text-white h-10 min-h-0 font-normal"><i className="ri-whatsapp-line ri-xl"></i> Chame a gente no Whatsapp</button>
                    </div>
                </div>
                
                    {/* Outras opções */}
                    <div>
                        <div className="flex flex-row gap-4 text-white items-center">
                            <h1 className="whitespace-nowrap">VEJA OUTRAS OPÇÕES</h1>
                            <hr className="h-0.5 bg-accent w-full" />
                        </div>
                    </div>

                    <div className="grid grid-cols-3 gap-11 mobile:grid-cols-1 mobile:gap-5">
                        {propaganda.map((x) => (
                            <CardVeiculo key={x.cod_veiculo} veiculo={x} />
                        ))}
                    </div>

                </div>

            </div>
        </>
    )
}