import { useContext, useEffect, useState } from "react";
import { Email, Endereco, InfosLoja, NomeLoja } from "../shared/types";
import { Endereco as EnderecoDictionary, Telefone as TelefoneDictionary, Email as EmailDictionary, NomeLoja as NomeLojaDictionary, DescLoja as DescLojaDictionary } from "../shared/utils/dictionary";
import { useNavigate } from "react-router-dom";

import logoLitoral from '../assets/logoLitoral.webp'
import { getEmails, getEnderecos, getInfosLoja, getLoja, postEmail, postWhats } from "../service/api";
import { AuthContext } from "../shared/context/auth";
import { isMobile } from "../shared/utils/javascript";
import { Resolver, useForm } from 'react-hook-form';
import { toast } from "react-toastify";

type FormValues = {
    nome: string,
    whatsapp: string,
    mensagem: string
  }

const resolver: Resolver<FormValues> = async (values) => {
    return {
      values: values.nome && values.whatsapp && values.mensagem  ? values : {},
      errors: {
        ...(values.nome ? {} : { nome: { type: "required", message: "Preencha este campo.", pattern: /^\d{3}\.\d{3}\.\d{3}-\d{2}$/ } }),
        ...(values.whatsapp ? {} : { whatsapp: { type: "required", message: "Preencha este campo." } }),
        ...(values.mensagem ? {} : { mensagem: { type: "required", message: "Preencha este campo." } }),
      },
    };
  };

export const Footer = () => {

    const { telefone, ip, email } = useContext(AuthContext);

    const navigate = useNavigate()
    const [endereco, setEndereco] = useState<Endereco[]>([])
    const [nomeLoja, setNomeLoja] = useState<NomeLoja>()
    const { register, handleSubmit, setError, clearErrors, formState: { errors }, reset, getValues, watch, setValue } = useForm<FormValues>({ resolver })

    const telefoneWhats = telefone.filter((x) => x.whatsapp === "1")
    const telefoneRamal = telefone.filter((x) => x.whatsapp === "0")

    const handleGetNomeLoja = async () => {
        try {
            const result = await getLoja()
            setNomeLoja(result?.data.retorno ?? {
                nome_empresa: ''
            }) 
        } catch {
            //
        }
    }
    const handleGetEndereco = async () => {
        try {
            const result = await getEnderecos()
            setEndereco(result?.data.retorno ?? []) 
        } catch {
            //
        }
    }

    const onSubmit = (data: any) => {
        postEmail({
            to: email,
            replyTo: data.email,
            subject: "Financiamento",
            html: {
                nome: data.nome,
                email: data.email,
                mensagem: data.mensagem
            },
            lojaId: Number(process.env.REACT_APP_COD_LOJA),
          })
          toast.success('Mensagem enviada com sucesso!')
        reset()
    }

    useEffect(() => {
        handleGetEndereco();
        handleGetNomeLoja();
    },[])

    return (
        <div className="bg-black text-white mobile:mb-12">
            <div className="w-full flex justify-center">
            <div className="py-10 px-3 max-w-[1500px] flex flex-row justify-center gap-16 mobile:flex-col mobile:px-3">
                <div className="flex flex-col gap-8 w-full">
                    <div className="flex flex-col gap-2 mobile:items-center">
                        <h1 className="text-2xl font-bold">Contato</h1>
                        <h2 className="text-xl">Confira nossos contatos</h2>
                    </div>

                <div className="flex flex-row gap-5 justify-between mobile:flex-col mobile:items-center mobile:gap-10">
                    <div className="flex flex-col gap-5 mobile:items-center">
                        <div className="flex flex-col gap-2 w-fit mobile:items-center">
                            <i className="ri-phone-fill ri-2x self-center"></i>
                            <h1 className="font-semibold mobile:text-xl">Telefone</h1>
                        </div>
                        <div className="flex flex-col gap-1 mobile:items-center">
                            {telefoneWhats.map((x) => (
                                <div className="flex items-center gap-3 cursor-pointer" key={x.telefone} onClick={() => {
                                        postWhats({
                                            ip: ip,
                                            dispositivo: isMobile() ? 'MOBILE' : 'DESKTOP',
                                            lojaId: Number(process.env.REACT_APP_COD_LOJA),
                                            codVeiculo: 0
                                        })
                                        window.open(`https://api.whatsapp.com/send?phone=+55${x[TelefoneDictionary.TELEFONE].replace(/[( )-]/g,"")}&text=Olá, tudo bem? Estou acessando o site e gostaria de mais informações.`, "_blank")
                                    }}>
                                    <h2>{x[TelefoneDictionary.TELEFONE]}</h2>
                                </div>
                            ))}
                            {telefoneRamal.map((x) => (
                                <div className="flex items-center gap-3 cursor-pointer" key={x.telefone} onClick={() => window.open(`tel:+55${x[TelefoneDictionary.TELEFONE].replace(/[( )-]/g,"")}`, "_blank")}>
                                    <h2>{x[TelefoneDictionary.TELEFONE]}</h2>
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="flex flex-col gap-5 mobile:items-center">
                        <div className="flex flex-col gap-2 w-fit">
                            <i className="ri-mail-line ri-2x self-center"></i>
                            <h1 className="font-semibold mobile:text-xl">E-mail</h1>
                        </div>
                        <div className="flex flex-col gap-1 mobile:items-center">
                            <h2 className="cursor-pointer">{email}</h2>
                        </div>
                    </div>

                    <div className="flex flex-col gap-5 mobile:items-center">
                        <div className="flex flex-col gap-2 w-fit">
                            <i className="ri-map-pin-2-fill ri-2x self-center"></i>
                            <h1 className="font-semibold mobile:text-xl">Endereço</h1>
                        </div>
                        <div className="flex flex-col gap-1 mobile:items-center">
                            <div className="flex flex-col gap-1 cursor-pointer mobile:items-center">
                                <h2>{endereco?.[0]?.[EnderecoDictionary.LOGRADOURO]}</h2>
                                <h2>{endereco?.[0]?.[EnderecoDictionary.BAIRRO]} - {endereco?.[0]?.[EnderecoDictionary.CIDADE]}, {endereco?.[0]?.[EnderecoDictionary.UF]}</h2>
                            </div>
                        </div>
                    </div>

                </div>

                </div>
                <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4 w-[48%] mobile:w-full text-black">
                    <div className="flex flex-row gap-4 mobile:flex-col">
                        <div className="w-full">
                            <input type="text" {...register("nome")} className="input rounded-none w-full h-9" placeholder="Nome" />
                            {errors?.nome && <p className='text-error text-xs absolute'>{errors.nome.message}</p>}
                        </div>
                        <div>
                            <input type="text" {...register("whatsapp")} className="input rounded-none min-w-52 w-full h-9" placeholder="WhatsApp" />
                            {errors?.whatsapp && <p className='text-error text-xs absolute'>{errors.whatsapp.message}</p>}
                        </div>
                    </div>
                    <div>
                        <textarea className="textarea textarea-bordered rounded-none w-full min-h-28" {...register("mensagem")} placeholder="Mensagem"></textarea>
                        {errors?.mensagem && <p className='text-error text-xs absolute'>{errors.mensagem.message}</p>}
                    </div>
                    <button className="btn btn-primary rounded-full max-h-0 font-bold min-h-9">Enviar mensagem</button>
                </form>
            </div>
            </div>
            <div className="bg-neutral py-2 flex items-center justify-center w-full">
                {isMobile() ? (
                    <div className="flex flex-col items-center px-3">
                        <h4 className="flex items-center gap-2 leading-5">Copyright © 2023 Todos os direitos reservados para {nomeLoja?.[NomeLojaDictionary.NOME_LOJA]} - Desenvolvido por</h4>
                        <img onClick={() => window.open("https://www.litoralcar.com.br/", "_blank")} className="max-h-5 cursor-pointer object-cover mt-[-22px] ml-16" src={logoLitoral} alt="Litoral Car" />
                        <span onClick={() => navigate('/termos')} className="underline mt-3 cursor-pointer">Politica de privacidade e termos de uso</span>
                    </div>
                ) : (
                    <h4 className="flex items-center gap-2">Copyright © 2023 Todos os direitos reservados para {nomeLoja?.[NomeLojaDictionary.NOME_LOJA]} - Desenvolvido por <img onClick={() => window.open("https://www.litoralcar.com.br/", "_blank")} className="max-h-5 cursor-pointer object-cover" src={logoLitoral} alt="Litoral Car" /> <span onClick={() => navigate('/termos')} className="underline cursor-pointer">Politica de privacidade e termos de uso</span></h4>
                )}
            </div>
        </div>
    )
}