import { Carousel } from "flowbite-react";
import { useContext, useEffect, useRef, useState } from "react";
import { isLaptop, isMobile, scrollToTop } from "../shared/utils/javascript";
import { Slider } from "../shared/types";
import { FiltroEstoque } from "../components/filtroEstoque";
import { CardVeiculo } from "../components/cardVeiculo";
import { useNavigate } from "react-router-dom";
import nossaHistoria from '../assets/nossaHistoria.webp';
import logo from '../assets/logo.webp'
import instagram from '../assets/instagram.webp'
import { DescLoja as DescLojaDictionary, InfosLoja as InfosLojaDictionary, Telefone as TelefoneDictionary } from "../shared/utils/dictionary";
import { buscarSlide, postWhats } from "../service/api";
import { AuthContext } from "../shared/context/auth";
import Select from 'react-select';
import { Endereco } from "../shared/utils/constants";
const Home: React.FC = () => {
    const navigate = useNavigate()
    const { estoque, ip, telefone } = useContext(AuthContext)
    const [slide, setSlide] = useState<Slider[]>([]);
    const [load, setLoad] = useState<boolean>(false);
    const [buscaRapida, setBuscaRapida] = useState(true);
    const [optionsMarcaModelo, setOptionsMarcaModelo] = useState([{value: "Busque por marca ou modelo", label: "Busque por marca ou modelo" }]);
    const [buscaDetalhadaSearch, setBuscaDetalhadaSearch] = useState(false);
    const [isDown, setIsDown] = useState(false);
    const [startX, setStartX] = useState(0);
    const [isMouseMoveWithinThreshold, setIsMouseMoveWithinThreshold] = useState(true);
    const [scrollLeft, setScrollLeft] = useState(0);
    const [waitToScroll, setWaitToScroll] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');
    const scrollRef = useRef<HTMLDivElement>(null);
    const [optionsMarca, setOptionsMarca] = useState([{img: "", label: "" }]);
    const telefoneWhats = telefone.find((x) => x.whatsapp === "1")
    const estilos = {
        root: {
          base: "relative h-full w-full",
          leftControl: "absolute top-0 left-0 flex h-full items-center justify-center px-4 focus:outline-none",
          rightControl: "absolute top-0 right-0 flex h-full items-center justify-center px-4 focus:outline-none"
        },
        indicators: {
          active: {
            off: "bg-accent hover:bg-white dark:bg-gray-800/50 dark:hover:bg-gray-800 hidden",
            on: "bg-primary dark:bg-primary hidden"
          },
          base: "h-2 w-2 rounded-full",
          wrapper: "absolute bottom-5 left-1/2 flex -translate-x-1/2 space-x-3"
        },
        item: {
          base: "absolute top-1/2 left-1/2 block w-full -translate-x-1/2 -translate-y-1/2",
          wrapper: {
            off: "w-full flex-shrink-0 transform cursor-default snap-center",
            on: "w-full flex-shrink-0 transform cursor-grab snap-center"
          }
        },
        control: {
          base: "inline-flex h-8 w-8 items-center justify-center rounded-full bg-white group-hover:bg-white/80 group-focus:outline-none group-focus:ring-4 group-focus:ring-white dark:bg-accent/30 dark:group-hover:bg-accent/60 dark:group-focus:ring-accent/70 sm:h-10 sm:w-10",
          icon: "h-5 w-5 text-primary dark:text-primary sm:h-6 sm:w-6"
        },
        scrollContainer: {
          base: "flex h-full snap-mandatory overflow-y-hidden overflow-x-scroll scroll-smooth rounded-none",
          snap: "snap-x"
        }
    };
    const estilosHiddenControls = {
        root: {
          base: "relative h-full w-full",
          leftControl: "absolute top-0 left-0 flex h-full items-center justify-center px-4 focus:outline-none",
          rightControl: "absolute top-0 right-0 flex h-full items-center justify-center px-4 focus:outline-none"
        },
        indicators: {
          active: {
            off: "hidden",
            on: "hidden"
          },
          base: "hidden",
          wrapper: "hidden"
        },
        item: {
          base: "absolute top-1/2 left-1/2 block w-full -translate-x-1/2 -translate-y-1/2",
          wrapper: {
            off: "w-full flex-shrink-0 transform cursor-default snap-center",
            on: "w-full flex-shrink-0 transform cursor-grab snap-center"
          }
        },
        control: {
          base: "hidden",
          icon: "hidden"
        },
        scrollContainer: {
          base: "flex h-full snap-mandatory overflow-y-hidden overflow-x-scroll scroll-smooth rounded-none",
          snap: "snap-x"
        }
    };
    const customStyles = {
      control: (provided: any) => ({
          ...provided,
          cursor: 'pointer',
          borderRadius: '80px',
          minWidth: '500px',
          height: '25px',
          paddingLeft: '10px',
          minHeigth: '25px',
        }),         
  };
  const customStylesMobile = {
      control: (provided: any) => ({
          ...provided,
          cursor: 'pointer',
          borderRadius: '80px',
          minWidth: '80vw',
          height: '25px',
          paddingLeft: '10px',
          minHeigth: '25px'
      }),  
  };
  const customStylesLaptop = {
      control: (provided: any) => ({
          ...provided,
          cursor: 'pointer',
          borderRadius: '80px',
          minWidth: '350px',
          height: '25px',
          paddingLeft: '10px',
          minHeigth: '25px'
      }),  
  };
  const handleSelectChange = (selectedOption: any) => {
    const paramsObj = { 
        busca: selectedOption.value,
    };

    const paramsString = encodeURIComponent(JSON.stringify(paramsObj));
  
    const urlComObjeto = `/estoque?params=${paramsString}`;
    navigate(urlComObjeto)
  };

  useEffect(() => {
    const marca = estoque.map((x) => {
        return x.marca
    })
    const listaMarca = marca.filter((element, index) => {
        return marca.indexOf(element) === index;
    }).sort((a, b) => a.localeCompare(b));

    const marcasObjetos = listaMarca.map(marca => ({
      img: `https://www.litoralcar.com.br/marcaswebapp/logo-${marca.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/\s+/g, '-')}.webp`,
      label: marca
    }));
    setOptionsMarca(marcasObjetos)
    }, [estoque])
  useEffect(() => {
    const marca = estoque.map((x) => {
        return x.marca
    })
    const listaMarca = marca.filter((element, index) => {
        return marca.indexOf(element) === index;
    });
    const marcaResult = listaMarca.map((x) => {
        return {value: x, label: x }
    })

    const modelo = estoque.map((x) => {
        return x.modelo
    })
    const listaModelo = modelo.filter((element, index) => {
        return modelo.indexOf(element) === index;
    });
    const modeloResult = listaModelo.map((x) => {
        return {value: x, label: x }
    })
    setOptionsMarcaModelo([...marcaResult, ...modeloResult])
},[estoque])
const handleNavigateFilterSelect = (selectedOption: any) => {
  const paramsObj = { 
    categoria: "",
    marca: selectedOption,
    modelo: "",
    precoDe: "",
    precoAte: "",
  };

  const paramsString = encodeURIComponent(JSON.stringify(paramsObj));

  const urlComObjeto = `/estoque?params=${paramsString}`;
  navigate(urlComObjeto)
};
    const handleGetSlide = async () => {
      const result = await buscarSlide();
      setSlide(result?.data)
  }
  useEffect(() => {
      handleGetSlide();
  },[])
    useEffect(() => {
        scrollToTop()
    },[window.location.pathname])
    const findBanner = () => {
      const mobile = slide?.filter((x: { isMobile: boolean; }) => x.isMobile === true) 
      const desktop = slide?.filter((x: { isMobile: boolean; }) => x.isMobile === false) 
      return isMobile() && mobile?.length > 0 ? mobile : desktop
    }
    function createMarkup(html: any) {
      return { __html: html };
    }
    useEffect(() => {
      const handleScroll = () => {
        if (!load) {
          setLoad(true);
        }
      };
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, [load]);

    useEffect(() => {
      let scrollInterval: NodeJS.Timeout;
  
      const handleScroll = () => {
        if (scrollRef.current) {
          const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;
          if (scrollLeft + clientWidth >= scrollWidth && !waitToScroll) {
            // Se o scroll atingir o final e não estiver esperando para rolar, definir a flag de espera
            setWaitToScroll(true);
            setTimeout(() => {
              if (scrollRef.current) {
                scrollRef.current.scrollTo({ left: 0, behavior: 'smooth' });
                setWaitToScroll(false); // Reiniciar a flag de espera após rolar de volta ao início
              }
            }, 5000); // Aguardar 5 segundos antes de rolar de volta ao início
          }
        }
      };

    const startScrollInterval = () => {
      // Iniciar um intervalo para rolar automaticamente a cada 5 segundos
      scrollInterval = setInterval(() => {
        if (scrollRef.current && !waitToScroll) {
          scrollRef.current.scrollBy({ left: 200, behavior: 'smooth' });
        }
      }, 5000); // 5000 milissegundos = 5 segundos
    };

    const stopScrollInterval = () => {
      // Parar o intervalo quando o componente for desmontado
      clearInterval(scrollInterval);
    };

    startScrollInterval();

    // Adicionar um listener para o evento de scroll
    if (scrollRef.current) {
      scrollRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollRef.current) {
        scrollRef.current.removeEventListener('scroll', handleScroll);
      }
      stopScrollInterval();
    };
  }, [waitToScroll]);
    return (
        <div className="bg-secondary">
            <div className="h-[73vh] mobile:h-[28vh] mobile:relative">
                <Carousel slideInterval={3000} theme={findBanner().length <= 1 ? estilosHiddenControls : estilos}>
                {findBanner().length === 0 ? (
                  <div className="w-full flex justify-center">
                    <span className="loading loading-dots loading-lg"></span>
                  </div>
                ) : findBanner()?.map((x: Slider) => (
                  <img key={x.id} className='object-cover h-full w-full' src={`${process.env.REACT_APP_FOTO}${x.arquivo}`} alt="..." />
              ))}
                </Carousel>
            </div>
            <div className="flex justify-center items-center desktop:px-3 w-full mt-[-1px]">
              <div className="py-10 w-full min-h-48 bg-black px-8 max-w-[95rem] rounded-b-3xl mobile:rounded-b-xl mobile:px-3 mobile:py-5 mobile:w-full flex items-center">
              <div className="flex gap-16 shadow justify-center items-center w-full mobile:flex-col mobile:gap-5">
              <div className="mobile:w-full">
                {buscaRapida ? (
                  <div className="flex flex-col gap-3 mobile:w-full">
                    <h2 className="text-xl font-normal text-white">Qual veículo você está buscando?</h2>
                    <Select
                      styles={isMobile() ? customStylesMobile : isLaptop() ? customStylesLaptop : customStyles}
                      aria-labelledby="Montserrat"
                      inputId="Montserrat"
                      name="select-marca-modelo"
                      placeholder="Busque por marca ou modelo"
                      onChange={handleSelectChange}
                      options={optionsMarcaModelo}
                    />
                  </div>
                ) : (
                <div className="flex flex-col gap-3 mobile:w-full">
                  <h2 className="text-white text-xl font-normal">Qual veículo você está buscando?</h2>
                  <FiltroEstoque home={true} buscaDetalhada={buscaDetalhadaSearch} />
                </div>  
                )}
                
              </div>
              <div className="gap-5 flex flex-col mobile:w-full">
                <div className="flex justify-around">
                  <div className="flex flex-row items-center gap-3 cursor-pointer" onClick={() => setBuscaRapida(true)}>
                    <input type="radio" name="radio-2" className="radio radio-white border-white w-3 h-3" readOnly checked={buscaRapida} />
                    <h3 className="text-white">Busca rápida</h3>
                  </div>
                  <div className="flex flex-row items-center gap-3 cursor-pointer" onClick={() => setBuscaRapida(false)}>
                    <input type="radio" name="radio-2" className="radio radio-white border-white w-3 h-3" readOnly checked={!buscaRapida} />
                    <h3 className="text-white">Busca avançada</h3>
                  </div>
                </div>
                <button onClick={() => {
                  if(buscaRapida) {
                    navigate('/estoque')
                  } else {
                    setBuscaDetalhadaSearch(true)
                  }
                }} className="btn btn-primary min-w-80 laptop:min-w-56 w-max font-extrabold rounded-2xl px-8 h-7 min-h-9 text-base mobile:w-full"><i className="ri-search-line ri-lg"></i> BUSCAR</button>
              </div>
              {buscaRapida && (
                <div className="flex w-full gap-10 max-w-lg items-center overflow-x-scroll overflow-y-hidden scroll-hidden" ref={scrollRef}
                onMouseDown={(e) => {
                  setIsDown(true);
                  setStartX(e.pageX - (scrollRef.current?.offsetLeft || 0));
                  setScrollLeft(scrollRef.current?.scrollLeft || 0);
                }}
                onMouseLeave={() => {
                  setIsDown(false);
                  setIsMouseMoveWithinThreshold(true); // Reseta a flag quando o mouse sai da área de rolagem
                }}
                onMouseUp={() => {
                  setIsDown(false);
                  if (isMouseMoveWithinThreshold) {
                    // Verifica se o mouse moveu dentro do limite antes de soltar
                    // Se sim, isso indica um clique e, portanto, dispara a ação desejada
                    handleNavigateFilterSelect(selectedOption);
                  }
                  setIsMouseMoveWithinThreshold(true); // Reseta a flag quando o mouse é solto
                }}
                onMouseMove={(e) => {
                  if (!isDown || !scrollRef.current) return;
                  e.preventDefault();
                  const x = e.pageX - (scrollRef.current?.offsetLeft || 0);
                  const walk = (x - startX) * 1;
                  scrollRef.current.scrollLeft = scrollLeft - walk;
          
                  // Verifica se o deslocamento do mouse está dentro de um limite
                  // Se não estiver, indica uma rolagem, então o clique não deve ser acionado
                  if (Math.abs(e.pageX - startX) > 5) {
                    setIsMouseMoveWithinThreshold(false);
                  }
                }}
              >
                {optionsMarca.map((x) => (
                  <div key={x.label} className="cursor-pointer bg-white min-w-28 min-h-28 w-28 h-28 flex items-center justify-center rounded-md hover:brightness-110" onMouseDown={() => setSelectedOption(x.label)}>
                    <img className="min-w-20 max-w-20 p-1" src={x.img} alt={x.label} />
                  </div>
                ))}
              </div>
              )}
            </div>
              </div>
            </div>
            <div className="flex flex-col gap-16 mt-8 justify-center items-center pb-6 mobile:pb-2 mobile:gap-8">
                <div className="grid grid-cols-3 gap-11 mobile:grid-cols-1 max-w-[95rem] px-3 mobile:gap-5 mobile:hidden w-full">
                    {estoque?.slice(0,6).map?.((x) => (
                        <CardVeiculo key={x.cod_veiculo} veiculo={x} />
                    ))}
                </div>
                <div className="grid grid-cols-4 px-8 gap-11 mobile:grid-cols-1 mobile:px-3 mobile:gap-5 desktop:hidden laptop:hidden">
                    {estoque?.slice(0,3).map?.((x) => (
                        <CardVeiculo key={x.cod_veiculo} veiculo={x} />
                    ))}
                </div>
                <button aria-label="Ver todos os veículos" onClick={() => navigate('/estoque')} className="btn btn-primary w-fit rounded-full px-28 font-bold"><i className="ri-car-line ri-lg"></i> ESTOQUE COMPLETO</button>
            </div>
                {load && (
                  <>
                  <div className="w-full flex justify-center mobile:mt-5">
                    <div className="bg-black flex mobile:flex-col mobile:w-[90%] mobile:gap-8 justify-between gap-20 p-8 w-fit mb-10 mt-2 rounded-2xl">
                      <div className="flex flex-col gap-3 text-white max-w-sm items-center text-center">
                        <h1 className="font-bold">Compramos seu veículo!</h1>
                        <h2>Compramos seu veículo sem burocracia e com agilidade.</h2>
                        <button onClick={() => {
                            postWhats({
                                ip: ip,
                                dispositivo: isMobile() ? 'MOBILE' : 'DESKTOP',
                                lojaId: Number(process.env.REACT_APP_COD_LOJA),
                                codVeiculo: 0
                            })
                            window.open(`https://api.whatsapp.com/send?phone=+55${telefoneWhats?.[TelefoneDictionary.TELEFONE].replace(/[( )-]/g,"")}&text=Olá, tudo bem? Estou acessando o site e gostaria de mais informações.`, "_blank")
                        }} className="btn btn-primary text-black rounded-full h-8 px-10 min-h-0 font-bold mt-5">Entre em contato</button>
                      </div>
                      <div className="flex flex-col gap-3 text-white max-w-sm items-center text-center">
                        <h1 className="font-bold">Avalie seu usado</h1>
                        <h2>Faça uma avaliação do seu carro on-line, no conforto da sua casa.</h2>
                        <button onClick={() => navigate('/avaliacao')} className="btn btn-primary text-black rounded-full h-8 px-10 min-h-0 font-bold mt-5">Avalie agora</button>
                      </div>
                      <div className="flex flex-col gap-3 text-white max-w-sm items-center text-center">
                        <h1 className="font-bold">Financie seu sonho</h1>
                        <h2>Trabalhamos com as principais financeiras, isso garante melhores taxas para você!</h2>
                        <button onClick={() => navigate('/financiamento')} className="btn btn-primary text-black rounded-full h-8 px-10 min-h-0 font-bold mt-5">Faça uma simulação</button>
                      </div>
                    </div>
                  </div>
                    <div className="w-full">
                        <iframe title='Mapa' src={Endereco.link} width="100%" height="100%" style={{border:"10px", height: "300px"}} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                  </>
                )}
        </div>
    )
}
export default Home;