export const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
};

export const isMobile = () => {
    return window.innerWidth < 1019 ? true : false
}

export const isLaptop = () => {
    return window.innerWidth < 1366 ? true : false
}