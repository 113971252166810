import { useContext, useEffect } from 'react';
import { DescLoja as DescLojaDictionary, InfosLoja as InfosLojaDictionary } from "../shared/utils/dictionary";
import { isMobile, scrollToTop } from '../shared/utils/javascript';
import { Endereco } from '../shared/utils/constants';
import { AuthContext } from '../shared/context/auth';

export const SobreNos = () => {

    const { infosLoja: loja } = useContext(AuthContext)
    function createMarkup(html: any) {
        return { __html: html };
    }

    useEffect(() => {
        scrollToTop()
    },[window.location.pathname])

    return (
        <div className="px-28 py-16 bg-secondary flex flex-col gap-14 mobile:px-3 mobile:gap-3">
            {/* Titulo */}
            <div className="flex flex-row items-center gap-3">
                <h1 className="min-w-max text-white font-semibold">{isMobile() ? "SOBRE A NOSSA EMPRESA" : "CONHEÇA UM POUCO SOBRE A NOSSA EMPRESA"}</h1>
                <hr className="h-0.5 bg-accent w-full" />
            </div>

            {/* Sobre nós */}
            <div className="flex flex-col items-center py-10 text-black mobile:py-5">
                <div className="grid grid-cols-3 gap-10 w-full items-center mobile:flex mobile:flex-col-reverse mobile:gap-5 mobile:shadow mobile:p-3">
                        <div className="w-full h-[50vh]">
                            <iframe title='Mapa' src={Endereco.link} width="100%" height="100%" style={{border:"10px"}} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                    <div className='h-full items-start justify-center flex col-span-2 shadow p-3 bg-black text-white'>
                        <p className="text-justify" dangerouslySetInnerHTML={createMarkup(loja?.[InfosLojaDictionary.LOJA]?.[DescLojaDictionary.TBL_INFOS_LOJA]?.[0]?.[DescLojaDictionary.DESCRICAO])} />
                    </div>
                </div>
            </div>
        </div>
    )
}