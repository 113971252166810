import { useContext, useEffect, useState } from 'react'
import { scrollToTop } from '../shared/utils/javascript'
import { AuthContext } from '../shared/context/auth';
import { postEmail } from '../service/api';
import { toast } from 'react-toastify';
import { Resolver, useForm } from 'react-hook-form';

type FormValues = {
    nome: string,
    email: string,
    sexo: string,
    nomeMae: string,
    celular: string,
    nascimento: string,
    cpfCnpj: string,
    uf: string,
    cidade: string,
    estadoCivil: string,
  }

  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const resolver: Resolver<FormValues> = async (values) => {
    return {
      values: values.nome && values.email && values.sexo && values.nomeMae && values.celular && values.nascimento && values.cpfCnpj && values.uf && values.cidade && values.estadoCivil ? values : {},
      errors: {
        ...(values.nome ? {} : { nome: { type: "required", message: "Preencha este campo.", pattern: /^\d{3}\.\d{3}\.\d{3}-\d{2}$/ } }),
        ...(values.email ? {} : { email: { type: "required", message: "Preencha este campo." } }),
        ...(values.email && !emailRegex.test(values.email) ? { email: { type: "pattern", message: "Email inválido" } } : {}),
        ...(values.sexo ? {} : { sexo: { type: "required", message: "Preencha este campo." } }),
        ...(values.nomeMae ? {} : { nomeMae: { type: "required", message: "Preencha este campo." } }),
        ...(values.celular ? {} : { celular: { type: "required", message: "Preencha este campo." } }),
        ...(values.nascimento ? {} : { nascimento: { type: "required", message: "Preencha este campo." } }),
        ...(values.cpfCnpj ? {} : { cpfCnpj: { type: "required", message: "Preencha este campo." } }),
        ...(values.uf ? {} : { uf: { type: "required", message: "Preencha este campo." } }),
        ...(values.cidade ? {} : { cidade: { type: "required", message: "Preencha este campo." } }),
        ...(values.estadoCivil ? {} : { estadoCivil: { type: "required", message: "Preencha este campo." } }),
      },
    };
  };

export const Financiamento = () => {

    const dataAtual = new Date();
    const data18 = new Date(dataAtual.getFullYear() - 18, dataAtual.getMonth(), dataAtual.getDate())
    const { email } = useContext(AuthContext);
    const optionsSexo = ["Sexo", "Masculino", "Feminino", "Outro"]

    const { register, handleSubmit, setError, clearErrors, formState: { errors }, reset, getValues, watch, setValue } = useForm<FormValues>({ resolver })
    const celularValue = watch("celular");
    const cpfValue = watch("cpfCnpj");

    const [etapa, setEtapa] = useState(0)

    const validarPrimeiraEtapa = () => {
        const data: any = getValues();

        // Validar se os sete primeiros campos estão preenchidos
        const camposObrigatorios: any = ['nome', 'nomeMae', 'email', 'celular', 'nascimento'];

        let algumCampoVazio = false;
        for (let campo of camposObrigatorios) {
          if (!data[campo]) {
            setError(campo, { type: 'required', message: `O campo ${campo} é obrigatório` }); // Definir erro para o campo
            algumCampoVazio = true;
          } else {
            clearErrors(campo); // Limpar o erro se o campo estiver preenchido
          }
        }

        if (algumCampoVazio) {
          return false; // Impedir o avanço se algum campo estiver vazio
        }

        // Se todos os campos obrigatórios estiverem preenchidos, avançar para a próxima etapa
        setEtapa(1)
        return true;
      };

    const onSubmit = (data: any) => {

        postEmail({
            to: email,
            replyTo: data.email,
            subject: "Financiamento",
            html: {
                nome: data.nome,
                email: data.email,
                sexo: data.sexo,
                nomeMae: data.nomeMae,
                celular: data.celular,
                nascimento: data.nascimento,
                cpfCnpj: data.cpfCnpj,
                uf: data.uf,
                cidade: data.cidade,
                estadoCivil: data.estadoCivil,
            },
            lojaId: Number(process.env.REACT_APP_COD_LOJA),
          })
          toast.success('Mensagem enviada com sucesso!')
        setEtapa(0)
        reset()
    }

    useEffect(() => {
        // Aplica a formatação ao valor do campo celular
        if (celularValue) {
            const apenasDigitos = celularValue.replace(/[^\d]/g, ''); // Remove não dígitos
            let celularFormatado = apenasDigitos;

            // Aplica a formatação apenas se houver 11 dígitos
            if (apenasDigitos.length === 11) {
            celularFormatado = apenasDigitos.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
            }

            // Define o valor formatado no campo celular
            setValue("celular", celularFormatado, { shouldValidate: true });
        }
        }, [celularValue]);

        useEffect(() => {
            // Verifica se é CPF ou CNPJ
            if (cpfValue) {
              const apenasDigitos = cpfValue.replace(/[^\d]/g, ''); // Remove não dígitos

              // Verifica se é CPF (11 dígitos)
              if (apenasDigitos.length === 11) {
                const cpfFormatado = apenasDigitos.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
                setValue("cpfCnpj", cpfFormatado, { shouldValidate: true });
              }
              // Verifica se é CNPJ (14 dígitos)
              else if (apenasDigitos.length === 14) {
                const cnpjFormatado = apenasDigitos.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
                setValue("cpfCnpj", cnpjFormatado, { shouldValidate: true });
              }
            }
        }, [cpfValue]);

    useEffect(() => {
        scrollToTop()
    },[window.location.pathname])

    return (
        <div className="px-28 py-16 bg-secondary flex flex-col gap-14 mobile:px-3 mobile:gap-5">
            {/* Titulo */}
            <div className="flex flex-row items-center gap-3">
                <h1 className="min-w-max text-white font-semibold">FINANCIAMENTO</h1>
                <hr className="h-0.5 bg-accent w-full" />
            </div>

            {/* Formulário de envio */}
            <form onSubmit={handleSubmit(onSubmit)} className='flex shadow w-fit self-center min-w-[35vw] bg-black'>
                <div className='p-10 gap-5 flex flex-col w-full mobile:px-3 mobile:py-8 mobile:w-full'>
                        <div className={etapa === 1 ? "hidden" : "p-10 gap-5 flex flex-col w-full mobile:px-3 mobile:py-8 mobile:w-full"}>
                            <h1 className='text-white'>Pensando em financiar? preencha os dados</h1>
                            <div>
                                <input value={watch("nome")} id='nome' {...register("nome")} type="text" placeholder="Nome" className="input input-bordered w-full" />
                                {errors?.nome && <p className='text-error text-xs absolute'>{errors.nome.message}</p>}
                            </div>

                            <select {...register("sexo")} className="select select-bordered w-full">
                                {optionsSexo.map((x, i) => (
                                    <option key={i}>{x}</option>
                                ))}
                            </select>

                            <div>
                                <input value={watch("nomeMae")} id='nomeMae' {...register("nomeMae")} type="text" placeholder="Nome da mãe" className="input input-bordered w-full" />
                                {errors?.nomeMae && <p className='text-error text-xs absolute'>{errors.nomeMae.message}</p>}
                            </div>

                            <div>
                                <input value={watch("email")} id='email' {...register("email")} type="text" placeholder="E-mail" className="input input-bordered w-full" />
                                {errors?.email && <p className='text-error text-xs absolute'>{errors.email.message}</p>}
                            </div>

                            <div>
                                <input value={watch("celular")} id='celular' {...register("celular")} type="text" placeholder="Celular" className="input input-bordered w-full" />
                                {errors?.celular && <p className='text-error text-xs absolute'>{errors.celular.message}</p>}
                            </div>

                            <div>
                                <input value={watch("nascimento")} id='nascimento' {...register("nascimento")} type="text" placeholder="Nascimento" className="input input-bordered w-full" />
                                {errors?.nascimento && <p className='text-error text-xs absolute'>{errors.nascimento.message}</p>}
                            </div>
                            <button className="btn btn-primary font-bold" onClick={() => {validarPrimeiraEtapa()}}>Próxima etapa</button>
                        </div>
                        <div className={etapa === 0 ? "hidden" : "p-10 gap-5 flex flex-col w-full mobile:px-3 mobile:py-8 mobile:w-full"}>
                            <h1 className='text-white'>Pensando em financiar? preencha os dados</h1>
                            <div>
                                <input value={watch("cpfCnpj")} id='cpfCnpj' {...register("cpfCnpj")} type="text" placeholder="CPF ou CNPJ" className="input input-bordered w-full" />
                                {errors?.cpfCnpj && <p className='text-error text-xs absolute'>{errors.cpfCnpj.message}</p>}
                            </div>

                            <div>
                                <input value={watch("uf")} id='uf' {...register("uf")} type="text" placeholder="UF" className="input input-bordered w-full" />
                                {errors?.uf && <p className='text-error text-xs absolute'>{errors.uf.message}</p>}
                            </div>

                            <div>
                                <input value={watch("cidade")} id='cidade' {...register("cidade")} type="text" placeholder="Cidade" className="input input-bordered w-full" />
                                {errors?.cidade && <p className='text-error text-xs absolute'>{errors.cidade.message}</p>}
                            </div>

                            <div>
                                <input value={watch("estadoCivil")} id='estadoCivil' {...register("estadoCivil")} type="text" placeholder="Estado civil" className="input input-bordered w-full" />
                                {errors?.estadoCivil && <p className='text-error text-xs absolute'>{errors.estadoCivil.message}</p>}
                            </div>
                                <div className='grid grid-cols-2 gap-5'>
                                    <button className="btn btn-secondary text-white btn-outline font-bold" onClick={() => setEtapa(0)}>Voltar</button>
                                    <button className="btn btn-primary font-bold" type='submit'>Enviar mensagem</button>
                                </div>
                        </div>
                </div>
            </form>
        </div>
    )
}
	