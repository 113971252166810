import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { isMobile } from '../shared/utils/javascript';
import { AuthContext } from '../shared/context/auth';

type BuscaQueryType = {
    categoria: string,
    marca: string,
    modelo: string,
    precoDe: string,
    precoAte: string,
    busca: string,
    [key: string]: string;
}

type FiltroEstoqueType = {
    home: boolean,
    buscaDetalhada?: boolean,
}

export const FiltroEstoque = ({home, buscaDetalhada}: FiltroEstoqueType) => {

    const location = useLocation()
    const { estoque } = useContext(AuthContext)
    const navigate = useNavigate()
    const [filtroSelect, setFiltroSelect] = useState({
        categoria: "",
        marca: "",
        modelo: "",
        precoDe: "",
        precoAte: "",
    });
    const [typeFilter, setTypeFilter] = useState(home ? false : true);
    const [buscaQuery, setBuscaQuery] = useState<BuscaQueryType>();
    const [optionsMarcaModelo, setOptionsMarcaModelo] = useState([{value: "Busque por marca ou modelo", label: "Busque por marca ou modelo" }]);
    const [optionsCategoria, setOptionsCategoria] = useState([{value: "Categoria", label: "Categoria" }]);
    const [optionsModelo, setOptionsModelo] = useState([{value: "Modelo", label: "Modelo" }]);
    const [optionsMarca, setOptionsMarca] = useState([{value: "Marca", label: "Marca" }]);
    const [optionsValorDe, setOptionsValorDe] = useState([{value: "Valor de", label: "Valor de" }]);
    const [optionsValorAte, setOptionsValorAte] = useState([{value: "Valor até", label: "Varlor até" }]);

    const customStyles = {
        control: (provided: any) => ({
            ...provided,
            cursor: 'pointer',
            borderRadius: '80px',
            minWidth: '600px',
            height: '38px',
            paddingLeft: '10px'
        }),
    };
    const customStylesMobile = {
        control: (provided: any) => ({
            ...provided,
            cursor: 'pointer',
            borderRadius: '80px',
            minWidth: '80vw',
            height: '38px',
            paddingLeft: '10px'
        }),
    };

    const handleSelectChange = (selectedOption: any) => {
        const paramsObj = { 
            busca: selectedOption.value,
        };

        const paramsString = encodeURIComponent(JSON.stringify(paramsObj));
      
        const urlComObjeto = `/estoque?params=${paramsString}`;
        navigate(urlComObjeto)
    };

    const handleNavigateFilterSelect = (selectedOption: any) => {
        const paramsObj = { 
            categoria: selectedOption.categoria === "Categoria" ? "" : selectedOption.categoria,
            marca: selectedOption.marca === "Marca" ? "" : selectedOption.marca,
            modelo: selectedOption.modelo === "Modelo" ? "" : selectedOption.modelo,
            precoDe: selectedOption.precoDe === "Valor de" ? "" : selectedOption.precoDe,
            precoAte: selectedOption.precoAte === "Valor até" ? "" : selectedOption.precoAte,
        };

        const paramsString = encodeURIComponent(JSON.stringify(paramsObj));
      
        const urlComObjeto = `/estoque?params=${paramsString}`;
        navigate(urlComObjeto)
    };

    const handleSelectChangeFiltro = async (tipo: string, value: string) => {
        setFiltroSelect(prevState => ({
            ...prevState,
            [tipo]: value
        }));
        if(tipo === "categoria") {
            setFiltroSelect(prevState => ({
                ...prevState,
                marca: ""
            }))
            setFiltroSelect(prevState => ({
                ...prevState,
                modelo: ""
            }))
            setFiltroSelect(prevState => ({
                ...prevState,
                precoAte: ""
            }))
            setFiltroSelect(prevState => ({
                ...prevState,
                precoDe: ""
            }))
        }
        if(tipo === "marca") {
            setFiltroSelect(prevState => ({
                ...prevState,
                modelo: ""
            }))
            setFiltroSelect(prevState => ({
                ...prevState,
                precoAte: ""
            }))
            setFiltroSelect(prevState => ({
                ...prevState,
                precoDe: ""
            }))
        }
        if(tipo === "modelo") {
            setFiltroSelect(prevState => ({
                ...prevState,
                precoDe: ""
            }))
            setFiltroSelect(prevState => ({
                ...prevState,
                precoAte: ""
            }))
        }
        if(tipo === "precoDe") {
            setFiltroSelect(prevState => ({
                ...prevState,
                precoAte: ""
            }))
        }
    }
    console.log(filtroSelect)
    
    const handleFilterInput = async () => {
        const categorias = estoque.map((x) => {
            return x.categoria
          })
        const listaCategorias = categorias.filter((element, index) => {
            return categorias.indexOf(element) === index;
        });
        listaCategorias.unshift("Categoria")
        setOptionsCategoria(listaCategorias.map((x) => {
            return {value: x, label: x }
        }))
    
        const filtroMarca = estoque.filter(item => {
            const categoriaMatch = filtroSelect.categoria === '' || filtroSelect.categoria === 'Categoria' || item.categoria === filtroSelect.categoria;
            
            return categoriaMatch;
          });        
          const marca = filtroMarca.map((x) => {
            return x.marca
        })
        const listaMarca = marca.filter((element, index) => {
            return marca.indexOf(element) === index;
        }).sort((a, b) => a.localeCompare(b));
        listaMarca.unshift("Marca")
        setOptionsMarca(listaMarca.map((x) => {
            return {value: x, label: x }
        }))
    
        const filtroModelo = estoque.filter(item => {
            const categoriaMatch = filtroSelect.categoria === '' || filtroSelect.categoria === 'Categoria' || item.categoria === filtroSelect.categoria;
            const marcaMatch = filtroSelect.marca === '' || filtroSelect.marca === 'Marca' || item.marca === filtroSelect.marca;
            
            return categoriaMatch && marcaMatch;
          });
        const modelo = filtroModelo.map((x) => {
            return x.modelo
        })
        const listaModelo = modelo.filter((element, index) => {
            return modelo.indexOf(element) === index;
        }).sort((a, b) => a.localeCompare(b));
        listaModelo.unshift("Modelo")
        setOptionsModelo(listaModelo.map((x) => {
            return {value: x, label: x }
        }))

        const filtroValorDe = estoque.filter(item => {
            const categoriaMatch = filtroSelect.categoria === '' || filtroSelect.categoria === 'Categoria' || item.categoria === filtroSelect.categoria;
            const marcaMatch = filtroSelect.marca === '' || filtroSelect.marca === 'Marca' || item.marca === filtroSelect.marca;
            const modeloMatch = filtroSelect.modelo === '' || filtroSelect.modelo === 'Modelo' || item.modelo === filtroSelect.modelo;

            return categoriaMatch && marcaMatch && modeloMatch;
          });
        const valorDe = filtroValorDe.map((x) => {
            return x.valor.toString()
        })
        const listaValorDe = valorDe.filter((element, index) => {
            return valorDe.indexOf(element) === index;
        });
        
        listaValorDe.unshift("Valor de")
        setOptionsValorDe(listaValorDe.sort((a,b) => Number(a) - Number(b)).map((x) => {
            return {value: x, label: x }
        }))

        const filtroValorAte = estoque.filter(item => {
            const categoriaMatch = filtroSelect.categoria === '' || filtroSelect.categoria === 'Categoria' || item.categoria === filtroSelect.categoria;
            const marcaMatch = filtroSelect.marca === '' || filtroSelect.marca === 'Marca' || item.marca === filtroSelect.marca;
            const modeloMatch = filtroSelect.modelo === '' || filtroSelect.modelo === 'Modelo' || item.modelo === filtroSelect.modelo;
            const valorDeMatch = filtroSelect.precoDe === '' || filtroSelect.precoDe === 'Valor de' || Number(filtroSelect.precoDe) <= Number(item.valor);
            
            return categoriaMatch && marcaMatch && modeloMatch && valorDeMatch;
          });
        const valorAte = filtroValorAte.map((x) => {
            return x.valor.toString()
        })
        const listaValorAte = valorAte.filter((element, index) => {
            return valorAte.indexOf(element) === index;
        });
        
        listaValorAte.unshift("Valor até")
        setOptionsValorAte(listaValorAte.sort((a,b) => Number(b) - Number(a)).map((x) => {
            return { value: x === "Valor até" ? "" : x, label: x }
        }))
    }
    
    useEffect(() => {
        const queryString = location.search;       
        const paramsString = new URLSearchParams(queryString).get('params');
    
        if (paramsString) {
            try {
                const paramsObj = JSON.parse(decodeURIComponent(paramsString));
                if(paramsObj.hasOwnProperty('categoria')) {
                    setTypeFilter(false)
                    setBuscaQuery(paramsObj)
                }
            } catch (error) {
                //
            }
        }
    },[])

    useEffect(() => {
        const marca = estoque.map((x) => {
            return x.marca
        })
        const listaMarca = marca.filter((element, index) => {
            return marca.indexOf(element) === index;
        });
        const marcaResult = listaMarca.map((x) => {
            return {value: x, label: x }
        })
    
        const modelo = estoque.map((x) => {
            return x.modelo
        })
        const listaModelo = modelo.filter((element, index) => {
            return modelo.indexOf(element) === index;
        });
        const modeloResult = listaModelo.map((x) => {
            return {value: x, label: x }
        })
        setOptionsMarcaModelo([...marcaResult, ...modeloResult])
    },[estoque])

    useEffect(() => {
        if(buscaDetalhada) handleNavigateFilterSelect(filtroSelect)
    }, [buscaDetalhada])
    
    useEffect(() => {
        handleFilterInput();
    },[estoque])

    useEffect(() => {
        handleFilterInput();
    },[filtroSelect])

    useEffect(() => {
        for (const propriedade in buscaQuery) {
            handleSelectChangeFiltro(propriedade, buscaQuery[propriedade])
        }
    },[buscaQuery])
    
    return (
        <div className={`w-full flex text-black ${window.location.pathname === "/" ? "justify-center" : ""}`}>
            {typeFilter === true ? (
                <div className='flex flex-row gap-5'>
                    <Select
                        styles={isMobile() ? customStylesMobile : customStyles}
                        aria-labelledby="Montserrat"
                        inputId="Montserrat"
                        name="select-marca-modelo"
                        placeholder="Busque por marca ou modelo"
                        onChange={handleSelectChange}
                        options={optionsMarcaModelo}
                    />
                    <div className='bg-primary cursor-pointer w-10 h-10 rounded-full flex justify-center items-center' onClick={() => setTypeFilter(!typeFilter)}>
                        <i className="ri-equalizer-line ri-xl text-white"></i>
                    </div>
                </div>
            ) : (
                <div className={`flex flex-row gap-5 w-full mobile:flex-wrap ${window.location.pathname === "/" ? "justify-center" : ""}`}>
                    {isMobile() ? (
                        <div className='grid grid-cols-1 gap-3 justify-center mobile:w-full'>
                            <div className='flex flex-row w-full justify-around gap-[inherit]'>
                                <select value={filtroSelect.categoria} onChange={(event) => handleSelectChangeFiltro('categoria', event.target.value)} className="select select-bordered select-radius min-w-[47%] max-h-10 min-h-10 mobile:w-full">
                                {optionsCategoria.map((x) => (
                                    <option key={x.value}>{x.label}</option>
                                ))}
                                </select>
                                <select value={filtroSelect.marca} onChange={(event) => handleSelectChangeFiltro('marca', event.target.value)} className="select select-bordered select-radius min-w-[47%] max-h-10 min-h-10 mobile:w-full">
                                    {optionsMarca.map((x) => (
                                        <option key={x.value}>{x.label}</option>
                                    ))}
                                </select>
                            </div>
                            <div className='flex flex-row w-full gap-[inherit]'>
                                <select value={filtroSelect.modelo} onChange={(event) => handleSelectChangeFiltro('modelo', event.target.value)} className="select select-bordered select-radius min-w-min max-w-[29vw] max-h-10 min-h-10 mobile:w-full">
                                    {optionsModelo.map((x) => (
                                        <option key={x.value}>{x.label}</option>
                                    ))}
                                </select>
                                <div className='flex flex-row w-full justify-center gap-[inherit]'>
                                <select value={filtroSelect.precoDe} onChange={(event) => handleSelectChangeFiltro('precoDe', event.target.value)} className="select select-bordered max-w-[30vw] select-radius max-h-10 min-h-10 mobile:w-full">
                                    {optionsValorDe.sort((a,b) => Number(a) - Number(b)).map((x) => (
                                        <option value={x.value} key={x.value}>{Number(x.label) ? Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(Number(x.label)) : x.label}</option>
                                    ))}
                                </select>
                                <select value={filtroSelect.precoAte} onChange={(event) => handleSelectChangeFiltro('precoAte', event.target.value)} className="select select-bordered max-w-[30vw] select-radius max-h-10 min-h-10 mobile:w-full">
                                    {optionsValorAte.map((x) => (
                                        <option value={x.value} key={x.value}>{Number(x.label) ? Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(Number(x.label)) : x.label}</option>
                                    ))}
                                </select>
                                </div>
                            </div>
                            {!home && (
                                <div className='flex flex-row gap-5 justify-around'>
                                    <button className='btn btn-primary rounded-2xl px-8 text-white min-w-[80vw] max-h-10 min-h-10' onClick={() => handleNavigateFilterSelect(filtroSelect)}>Buscar resultado</button>
                                    <div className='bg-primary cursor-pointer w-10 h-10 min-w-10 min-h-10 rounded-full flex justify-center items-center' onClick={() => setTypeFilter(!typeFilter)}>
                                        <i className="ri-equalizer-line ri-xl text-white"></i>
                                    </div>
                                </div>
                            )}
                        </div>
                    ) : (
                        <>
                        <select value={filtroSelect.categoria} onChange={(event) => handleSelectChangeFiltro('categoria', event.target.value)} className="select select-bordered min-w-52 select-radius max-h-10 min-h-10 mobile:w-full">
                            {optionsCategoria.map((x) => (
                                <option key={x.value}>{x.label}</option>
                            ))}
                        </select>
                        <select value={filtroSelect.marca} onChange={(event) => handleSelectChangeFiltro('marca', event.target.value)} className="select select-bordered min-w-52 select-radius max-h-10 min-h-10 mobile:w-full">
                            {optionsMarca.map((x) => (
                                <option key={x.value}>{x.label}</option>
                            ))}
                        </select>
                        <select value={filtroSelect.modelo} onChange={(event) => handleSelectChangeFiltro('modelo', event.target.value)} className="select select-bordered min-w-52 select-radius max-h-10 min-h-10 mobile:w-full">
                            {optionsModelo.map((x) => (
                                <option key={x.value}>{x.label}</option>
                            ))}
                        </select>
                        <select value={filtroSelect.precoDe} onChange={(event) => handleSelectChangeFiltro('precoDe', event.target.value)} className="select select-bordered min-w-40 select-radius max-h-10 min-h-10 mobile:w-full">
                            {optionsValorDe.sort((a,b) => Number(a) - Number(b)).map((x) => (
                                <option value={x.value} key={x.value}>{Number(x.label) ? Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(Number(x.label)) : x.label}</option>
                            ))}
                        </select>
                        <select value={filtroSelect.precoAte} onChange={(event) => handleSelectChangeFiltro('precoAte', event.target.value)} className="select select-bordered min-w-40 select-radius max-h-10 min-h-10 mobile:w-full">
                            {optionsValorAte.map((x) => (
                                <option value={x.value} key={x.value}>{Number(x.label) ? Intl.NumberFormat('pt-br', { style: 'currency', currency: 'BRL' }).format(Number(x.label)) : x.label}</option>
                            ))}
                        </select>
                        {!home && (
                            <div className='flex flex-row gap-5'>
                                <button className='btn btn-primary rounded-2xl px-8 text-white max-h-10 min-h-10' onClick={() => handleNavigateFilterSelect(filtroSelect)}>Buscar resultado</button>
                                <div className='bg-primary cursor-pointer w-10 h-10 rounded-full flex justify-center items-center max-h-10 min-h-10' onClick={() => setTypeFilter(!typeFilter)}>
                                    <i className="ri-equalizer-line ri-xl text-white"></i>
                                </div>
                            </div>
                        )}
                        </>
                    )}
                </div>
            )}
        </div>
    )
}